.react-3d-carousel .slider-container .slider-content .slider-single .slider-single-content {
    box-shadow: none !important;
}

.divider:after {
    background-color: #ffff;
}
.divider:before {
    background-color: #ffff;
}

.swiper-button-next:after {
    color: #ffff;
    font-size: 26px !important;
    padding-right: 40px;
}
.swiper-button-prev:after {
    color: #ffff;
    font-size: 26px !important;
}
@media screen and (max-width: 767px){
    .swiper-button-prev:after {
        color: #3E3E3E;
        padding-bottom: 50px;
    }
    .swiper-button-next:after {
    color: #3E3E3E;
    padding-right: 0px !important;
    padding-bottom: 50px;
}
}

.prev .next {
    color:#ffff;
}